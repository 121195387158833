define("discourse/plugins/all-in-one/discourse/templates/connectors/topic-category/all-in-one", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span>Topic ID: {{formatTopicIdBase36 @outletArgs.topic.id}}</span>
  
  */
  {
    "id": "o7kgsXkD",
    "block": "[[[10,1],[12],[1,\"Topic ID: \"],[1,[28,[35,0],[[30,1,[\"topic\",\"id\"]]],null]],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"formatTopicIdBase36\"]]",
    "moduleName": "discourse/plugins/all-in-one/discourse/templates/connectors/topic-category/all-in-one.hbs",
    "isStrictMode": false
  });
});