define("discourse/plugins/all-in-one/discourse/initializers/all-in-one", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'all-in-one',
    initialize() {
      (0, _pluginApi.withPluginApi)('1.6.1', api => {
        api.decorateWidget('header-buttons:before', helper => {
          return helper.rawHtml('<a href="https://evlambda.org" style="margin-right: 1em;">Return to E/V Lambda home page</a>');
        });
      });
    }
  };
});